import React from "react";
import SubPage from "../components/SubPage/SubPage";
import SubText from "../components/SubPage/SubText";
import regulamin from "../data/regulamin.json";
import styled from "styled-components";
import GlobalStyle from "../styles/GlobalStyles";

function Regulations() {
  return (
    <SubPage>
      <GlobalStyle whiteMode />
      {regulamin.map((el, index) => (
        <SubText text={el} key={index}>
          {el.bolds &&
            el.bolds.map((bold) => (
              <Point key={index}>
                {" "}
                <BoldTitle>{bold.boldTitle}</BoldTitle>
                {bold.text}
              </Point>
            ))}
        </SubText>
      ))}
    </SubPage>
  );
}

export default Regulations;
const Point = styled.p`
  font-size: 1.2rem;
  line-height: 1.2;
`;
const BoldTitle = styled.span`
  font-weight: 700;
`;
